@import "../../../../scss/module";

.committee {

  @include media-breakpoint-up(md) {
    margin-bottom: -12rem;
  }

  &[class*=visible] &__card {
    opacity: 1;
    transform: translateY(-10%);

    @include media-breakpoint-up(md) {
      transform: translateY(-50%);
    }

    &:nth-of-type(1) {
      transition-delay: .25s;
    }

    &:nth-of-type(2) {
      transition-delay: .5s;
    }

    &:nth-of-type(3) {
      transition-delay: .75s;
    }

    &:nth-of-type(4) {
      transition-delay: 1s;
    }

    a {
      color: $white;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  [class*=col-] {
    min-height: 582px;

    &:nth-of-type(1) {
      position: relative;
    }

    blockquote {

      svg {
        fill: $white;
      }

      hr {
        display: block;
        background-color: $white;
        width: 32px;
        height: 3px;
        opacity: 1;
        border: 0;
      }

      cite {
        display: block;
        font-style: normal;
      }
    }
  }

  &__card {
    transform: translateY(-40%);
    background-color: $primary;
    color: $white;
    opacity: 0;
    transition: .75s;

    > div > img {
      width: 37px;
    }

    @include media-breakpoint-up(md) {
      transform: translateY(0%);
    }
  }

  &__title {
    writing-mode: vertical-lr;
    transform: rotate(180deg) translateX(100%);
    position: absolute;
    bottom: -24px;
    left: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;

    &:before {
      content: '';
      display: block;
      height: 110px;
      width: 5px;
      margin-bottom: 20px;
    }
  }

  @each $name, $color in $brand-colors {
    &[class*=variant-#{$name}] &__title:before {
      background-color: $color;
    }
    &[class*=variant-#{$name}] &__content a {
      color: $color;
    }

    @if $name == 'quaternary' {
      &[class*=variant-#{$name}] &__card {
        &:nth-of-type(1) {
          background-color: $secondary;
        }
        &:nth-of-type(2) {
          background-color: $senary;
        }
        &:nth-of-type(3) {
          background-color: $quaternary;
        }
        &:nth-of-type(4) {
          background-color: $tertiary;
        }
      }
    }
  }
}