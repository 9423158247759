@import "src/scss/module";

.awards {

  a {
    color: $primary;
  }

  img {
    //filter: brightness(0);
    //opacity: 0.4;
    transition: 1s;

    &:hover {
      scale: 1.1;
    }
  }

  figcaption {
    color: #444444;
    font-size: $font-size-sm;
  }

  @each $name, $color in $brand-colors {
    &[class*=variant-#{$name}] {
      a {
        color: $color;
      }
    }
  }
}