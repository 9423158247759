@import "src/scss/module";

.carousel {

  [class=carousel-control-prev] {
    width: 50px;
    height: 50px;
    padding: .8em;
    background-color: $secondary;
    opacity: 1;
    right: 0;
    left: auto;
    transform: translate(50%, 100%);

    [class*=-icon] {
      background-image: url("data:image/svg+xml,<svg width='15' height='12' viewBox='0 0 15 12' fill='white' xmlns='http://www.w3.org/2000/svg'><path d='M0.157895 6.018L5.19474 11.0833C5.41579 11.3056 5.76316 11.3056 5.96842 11.0833C6.18947 10.861 6.18947 10.5116 5.96842 10.3052L1.86316 6.17678H14.4474C14.7474 6.17678 15 5.92272 15 5.62103C15 5.31934 14.7474 5.06528 14.4474 5.06528H1.87895L5.98421 0.936838C6.20526 0.714538 6.20526 0.365208 5.98421 0.158786C5.87368 0.0476358 5.73158 0 5.58947 0C5.44737 0 5.30526 0.0476358 5.19474 0.158786L0.157895 5.22406C0.0473684 5.33522 0 5.46224 0 5.62103C0 5.77982 0.0631579 5.90685 0.157895 6.018Z'/></svg>");
    }
  }

  [class=carousel-control-next] {
    width: 50px;
    height: 50px;
    padding: .8em;
    background-color: $secondary;
    opacity: 1;
    transform: translate(50%, 200%);

    [class*=-icon] {
      background-image: url("data:image/svg+xml,<svg width='15' height='12' viewBox='0 0 15 12' fill='white' xmlns='http://www.w3.org/2000/svg'> <path d='M14.8421 6.018L9.80526 11.0833C9.58421 11.3056 9.23684 11.3056 9.03158 11.0833C8.81053 10.861 8.81053 10.5116 9.03158 10.3052L13.1368 6.17678H0.55263C0.25263 6.17678 0 5.92272 0 5.62103C0 5.31934 0.25263 5.06528 0.55263 5.06528H13.1211L9.01579 0.936838C8.79474 0.714538 8.79474 0.365208 9.01579 0.158786C9.12632 0.0476358 9.26842 0 9.41053 0C9.55263 0 9.69474 0.0476358 9.80526 0.158786L14.8421 5.22406C14.9526 5.33522 15 5.46224 15 5.62103C15 5.77982 14.9368 5.90685 14.8421 6.018Z'/> </svg> ");
    }
  }

  &:has([class*="carousel-indicators"]) {
    padding-bottom: 1rem;
  }

  [class*="carousel-indicators"] {
    margin-bottom: 0;

    button {
      border: 1px solid $primary;
      background-color: transparent;
      width: 10px;
      height: 10px;
      border-radius: 100px;
      overflow: hidden;
      transition: 0.4s;

      &[class*="active"] {
        background-color: $primary;
      }
    }
  }
}
