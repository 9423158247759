@import "src/scss/module";

.statistics {

  h3 {
    @include media-breakpoint-up(md) {
      writing-mode: vertical-lr;
      transform: rotate(180deg);
    }
  }

  .card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    figure {
      width: 55px;
      height: 55px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0;

      img {
        max-width: 100%;
        max-height: 100%;
      }

      @include media-breakpoint-up(sm) {
        width: 65px;
        height: 65px;
      }

      @include media-breakpoint-up(md) {
        width: 85px;
        height: 85px;
      }
    }

    dl {
      width: 150px;
      display: flex;
      flex-direction: column-reverse;

      dd {
        font-weight: $font-weight-medium;
        margin-bottom: 0;
        font-size: 32px;
        @include media-breakpoint-up(sm) {
          font-size: 36px;
        }
        @include media-breakpoint-up(md) {
          font-size: 40px;
        }
      }

      dt {
        font-weight: $font-weight-medium;
        margin-bottom: 0;
        font-size: 16px;
        @include media-breakpoint-up(md) {
          font-size: 18px;
        }
      }
    }

  }
}