@import 'src/scss/module';

.expectations {

  ul {
    li {
      margin-bottom: $spacer;
    }
  }

  .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    figure {
      width: 125px;
      height: 125px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

  }

  .footer {
    color: $white;

    p {
      margin-bottom: 0;
    }

    a {
      color: $white;
      text-decoration: underline;
    }
  }
}