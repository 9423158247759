@import "src/scss/module";

.card {
  border-radius: 0;
  border: none;
  height: 100%;

  [class*=card-title] {
    min-height: 72px;

    a {
      text-decoration: none !important
    }
  }
  :global {

    .card-body {
      display: flex;
      flex-direction: column;
    }

    .card-img {

      img {
        width: 100%;
      }

      .badge {
        position: absolute;
        top: 0;
        right: 0;
        margin: $spacer;
        border-radius: 100px;
        font-size: 14px;
        text-decoration: none;
      }
    }
  }

  img {
    border-radius: 0;
  }

  dl {
    display: flex;
    gap: $spacer * .5;
    margin-bottom: $spacer * .25;
    max-width: 300px;

    dt {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0;
    }

    dd {
      display: flex;
      font-size: 16px;
      margin-bottom: 0;
    }
  }
}