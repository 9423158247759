@import "src/scss/module";

.process {

  h3 {
    @include media-breakpoint-up(lg) {
      white-space: nowrap;
      writing-mode: vertical-lr;
      transform: rotate(180deg) translateX(100%);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 50px;

      &:before {
        content: '';
        display: block;
        background-color: $primary;
        height: 110px;
        width: 5px;
        margin-bottom: 20px;
      }
    }
  }

  .card {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(sm) {
      filter: grayscale(1) opacity(.25);
      transition: .3s;
      height: 320px;
      flex-direction: row;

      &[class*=active] {
        filter: grayscale(0) opacity(1);

        .details {
          transform: translateX(-20px);

          i:before {
            transform: translate(calc(-100% + 20px), -50%);
          }
        }
      }
    }

    .intro {
      position: relative;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-around;
      padding: 2rem 0;

      @include media-breakpoint-up(sm) {
        padding: 0;
        width: 33%;

        &:before {
          display: block;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, -50%);
          content: '';
          width: 3px;
          height: 108px;
          background-image: url("data:image/svg+xml,%3csvg width='3' height='103' viewBox='0 0 3 108' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cline x1='1.5' y1='6.55671e-08' x2='1.5' y2='103' stroke='%23213867' stroke-width='3' stroke-dasharray='6 6'/%3e%3c/svg%3e");
        }
      }

      img {
        width: 65px;

        @include media-breakpoint-up(sm) {
          width: 75px;
        }

        @include media-breakpoint-up(md) {
          width: 85px;
        }
      }

      h4 {
        color: $primary;
      }
    }

    &:first-of-type .intro:before {
      display: none;
    }

    .details {
      flex-grow: 1;
      display: flex;
      align-items: center;
      transition: .3s;

      div {
        position: relative;
        margin-left: 20px;
        flex-grow: 1;

        @include media-breakpoint-up(md) {
          margin-left: 145px;
        }

        div {

          > *:last-child {
            margin-bottom: 0;
          }
        }

        i {
          position: absolute;
          left: 0;
          top: 50%;
          transform: translate(-50%, -50%);
          background-color: $secondary;
          color: $white;
          width: 54px;
          height: 54px;
          border-radius: 100px;
          display: flex;
          font-size: 37px;
          font-style: normal;
          align-items: center;
          justify-content: center;

          @include media-breakpoint-up(sm) {
            width: 64px;
            height: 64px;
          }

          @include media-breakpoint-up(md) {
            width: 74px;
            height: 74px;

              &:before {
                display: block;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(-100%, -50%);
                transition: .3s;
                content: '';
                height: 3px;
                background-image: url("data:image/svg+xml,%3csvg width='108' height='3' viewBox='0 0 108 3' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cline x1='108' y1='1.5' x2='-1.31134e-07' y2='1.49999' stroke='%23213867' stroke-width='3' stroke-dasharray='6 6'/%3e%3c/svg%3e");
                width: 108px;
            }
          }
        }
      }
    }
  }
}