@import '../../scss/module';

.card {
  display: flex;
  flex-direction: column;
  transition: $transition-base;

  &:hover {
    animation: jump .6s ease-in-out;
  }

  dl {
    display: flex;
    border-bottom: 2px solid $border-color;
    margin-bottom: 0;
    padding: .25em 0;
    font-size: 15px;

    dt {
      width: 100px;
      font-weight: $font-weight-medium;
      flex-shrink: 0;
    }

    dd {
      margin-bottom: 0;
    }
  }

  p {
    font-size: 15px;
    flex-grow: 1;
  }

  a {
    color: $dark;
    display: inline-block;
    text-decoration: none;
    border-bottom: 3px solid $primary;
    transition: $transition-base;

    &:hover, &:focus, &:active {
      color: $secondary;
      border-color: $secondary;
    }
  }
}