@import 'src/scss/module';

.solutions {
  position: relative;
  border: 1px solid #B3B3B3;

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    @include media-breakpoint-up(md) {
      object-position: center right;
    }
  }

  &[class*=visible] {

    .card {
      transform: translateX(-1em);
      opacity: 1;
    }
  }

  .card {
    display: flex;
    max-width: 620px;
    background-color: rgba($white, .2);
    border-left: 5px solid $primary;
    transform: translateX(-10em);
    backdrop-filter: blur(5px);
    box-shadow: 0 0 30px rgba($black, 0.15);
    text-decoration: none;
    color: $dark;
    transition-duration: .75s, .75s, .5s;
    transition-property: transform, opacity, background-color;
    opacity: 0;

    &:nth-of-type(1) {
      transition-delay: .5s, .5s, 0s;
    }

    &:nth-of-type(2) {
      transition-delay: .75s, .75s, 0s;
    }

    p {
      font-size: 16px;
    }

    &:hover {
      background-color: rgba($white, .9);
    }

    [role=button]:before {
      margin: 0;
    }
  }
}