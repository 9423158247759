@import "bootstrap/scss/variables";

$primary: #FC470E;
$secondary: #213867;
$tertiary: #9DA2D2;
$quaternary: #BD338A;
$quinary: #8BC9AB;
$senary: #FAC140;
$light: #F6F4FA;
$dark: #2a2a2a;
$gray: #E5E4E8;

$brand-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "tertiary": $tertiary,
  "quaternary": $quaternary,
  "quinary": $quinary,
  "senary": $senary,
);

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "tertiary": $tertiary,
  "quaternary": $quaternary,
  "quinary": $quinary,
  "senary": $senary,
  "light": $light,
  'gray': $gray,
  "dark": $dark,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1300px,
);

$h1-font-size: clamp(24px, 7vw, 50px);
$h2-font-size: clamp(24px, 6vw, 40px);
//$h2-font-size: clamp(20px, 5.5vw, 35px);
$h3-font-size: $font-size-base * 1.875;
$h4-font-size: $font-size-base * 1.75;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base * 1.1;


@keyframes pulse {
  0%   {opacity: 1}
  50%  {opacity: 0}
  100% {opacity: 1}
}

@keyframes jump {
  0%   {transform: translateY(0)}
  50%  {transform: translateY(-1rem)}
  100% {transform: translateY(0)}
}
@keyframes wiggle {
  0%   {transform: translateX(0)}
  50%  {transform: translateX(0.5rem)}
  100% {transform: translateX(0)}
}