@import "src/scss/module";

.history {

  h3 {
    @include media-breakpoint-up(md) {
      writing-mode: vertical-lr;
      transform: rotate(180deg);
    }
  }

  .record {

    & > div {

      &:nth-of-type(1) {
        position: relative;
        border-right: 5px solid $primary;
        padding-right: .75em;
        margin-right: .75em;

        @include media-breakpoint-up(lg) {
          padding-right: 1em;
          margin-right: 1em;
        }

        time {
          font-weight: $font-weight-bold;
          font-size: $h3-font-size;
          color: $primary;
          writing-mode: vertical-lr;
          transform: rotate(-180deg);

          @include media-breakpoint-up(lg) {
            writing-mode: lr;
            transform: none;
          }
        }

        &:after {
          content: '';
          display: block;
          background-color: $primary;
          border: 5px solid $primary;
          border-radius: 100px;
          width: 24px;
          height: 24px;
          position: absolute;
          right: 0;
          top: 0;
          transform: translate(calc(50% + 2px), 50%);
        }
      }
    }
  }
}