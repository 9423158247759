@import "../../../../scss/module";

.images {

  figure {
    overflow: hidden;
  }

  img {
    width: 100%;
    transition: 1s;

    &:hover {
      scale: 1.1;
    }
  }

  [class*=col] {

    &:nth-of-type(1) {
      flex-basis: 100%;

      @include media-breakpoint-up(md) {
        display: flex;
        flex-direction: row;

        figure {
          width: 33%;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      @include media-breakpoint-up(lg) {
        flex-basis: 26.5%;
        flex-direction: column;

        figure {
          width: auto;
        }
      }
    }

    &:nth-of-type(2) {
      flex-basis: 100%;

      @include media-breakpoint-up(md) {
        display: flex;
        flex-direction: row;

        figure {
          width: 50%;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      @include media-breakpoint-up(lg) {
        flex-basis: 43.3%;
        flex-direction: column;

        figure {
          width: auto;
        }
      }
    }

    &:nth-of-type(3) {
      flex-basis: 100%;

      @include media-breakpoint-up(md) {
        display: flex;
        flex-direction: row;

        figure {

          &:nth-of-type(1) {
            width: 60%;
          }

          &:nth-of-type(2) {
            width: 40%;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      @include media-breakpoint-up(lg) {
        flex-basis: 29%;
        flex-direction: column;

        figure {
          width: auto !important;
        }
      }
    }
  }
}